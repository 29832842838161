
.wrap .gform_wrapper {

	label.gfield_label {
		font-weight: 500;
		color:$primary;
	}

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), select {
		height:40px;
		border:1px solid $primary;
		padding:0px 10px;
	}

	textarea {
		border:1px solid $primary;
	}

	/**
	 *	Validation Styles
	 */

	div.validation_error {
		border:0;
		background-color: $error;
		color:#fff;
		font-weight: 500;
		padding:10px 10px;
		display: block;
		margin-top:30px;
		margin-bottom:0;
		line-height: 1.5em;
	}

	&.gform_validation_error .top_label li.gfield.gfield_error {
		background-color: $error;
		padding:0px 10px;
		label {
			color:#fff;
		}
		input::placeholder {
			color:#fff;
		}
		.ginput_container {
			padding-right:10px;
		}
		.validation_message {
			color:#fff;
			font-weight: normal;
			line-height: 1.5em;
			padding-top: 0;
			padding-bottom:5px;
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), select, textarea {
			background-color: transparent;
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}