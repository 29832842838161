// Colours
$dark-teal: rgb(0, 48, 60);
$cherry: rgb(215, 0, 53);

$error: #d52d2d;

$grey-dark: rgb(72, 72, 72);
$grey-med:rgb(218, 218, 218);
$grey-light: rgb(242,242,242);

$lightBrown: #d0c492;

$primary: $dark-teal;
$secondary: $cherry;

$pColor: $grey-dark;
$hColor: $primary;

// Fonts
$adelle: "adelle",serif;
$montserrat: 'Montserrat', sans-serif;

$pFont: $montserrat;
$hFont: $montserrat;

$h1Font: $adelle;

// Other
$transition: all 0.3s ease;

$utility-bar-height-desktop: 32px;

$header-mobile-height: 70px;
$header-desktop-height: 90px;
