.single.single-property {
	.property-detail-header {
		display: block;
		width: 100%;
		padding-top: calc(15vw + #{$header-desktop-height});
		padding-bottom: 15vw;

		background: {
			size: cover;
			position: center;
		}
	}

	.property-detail-info {
		padding: 50px 0;
		.intro {
			text-align: center;
		}
		.property-title {
			font-family: $h1Font;
			margin:0 0 5px 0;
		}
		.property-sub-title {
			margin:0;
		}
		.property-city {
			font-weight: 600;
		}
		.property-completion {
			font-style: italic;
		}
		.property-website {
			margin-top: 45px;
		}

		.property-pdfs {
			.file-container {
				margin-top: 20px;
				display: table;
				i,
				.file {
					color: $primary;
					display: table-cell;
					vertical-align: middle;
				}
				i {
					font-size: 33px;
				}
				.file {
					line-height: 22px;
					font-weight: 600;
					padding-left: 5px;
					text-decoration: none;
					transition: $transition;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}

	.property-additional-features {
		padding: 50px 0;
		.features-title {
			margin-top: 0;
			display: block;
			text-align: center;
		}
		.feature {
			background-color: $primary;
			margin: 0 auto;
			width: 125px;
			height: 125px;
			padding: 20px;

			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-box-align: center !important;
			-ms-flex-align: center !important;
			align-items: center !important;
			text-align: center;
			.feature-info {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
				.title,
				.description {
					display: block;
					color: $lightBrown;
				}
				.title {
					font-size: 26px;
					font-weight: 500;
					margin-bottom: 5px;
				}
				.description {
					font-size: 14px;
					line-height: 1.5em;
					font-weight: normal;
				}
			}
		}
	}
	.property-visual-identity {
		padding: 50px 0;
		.property-logo {
			max-width:400px;
			text-align: center;
			img {
				width:100%;
			}
		}
		.property-gallery {
			position: relative;
			margin-bottom: 30px;
			img {
				width: 100%;
				padding-bottom: 56.25%;
				background: {
					size: cover;
					position: center;
				}
			}
			.paging-info {
				position: absolute;
				bottom: -28px;
				right: 70px;
			}
		}

		.slick-arrow {
			position: absolute;
			bottom: -30px;
			transition: $transition;
			cursor: pointer;
			font-size: 20px;
			color: $primary;
			&:hover {
				color: $secondary;
			}
			&:focus {
				outline: none;
			}
			&.slick-prev {
				right: 35px;
			}
			&.slick-next {
				right: 0;
			}
			&.slick-disabled {
				opacity: 0.2;
				cursor: default;
				&:hover {
					color: $primary;
				}
			}
		}
	}

	.property-leasing {
		margin-top:50px;
		margin-bottom: 50px;
		text-align: center;
		.container {
			.row-border {
				margin-right: 0;
				margin-left: 0;
				border: 3px solid $lightBrown;
				padding-top: 75px;
				padding-bottom: 75px;
			}
			h2 {
				margin-top: 0;
				margin-bottom: 10px;
			}
			h4 {
				margin-bottom:0;
			}
			.leasing-intro {
				margin-bottom:30px;
				.contact-us {
					margin-top: 35px;
				}
			}
		}
		.agent {
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: solid 2px #d4d4d4;
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
				border: none;
			}

			.name {
				margin: 0 0 5px 0;
			}
			.title {
				color: $primary;
				font-weight: 500;
			}
			ul {
				@include list();
				li {
					font-size: 14px;
					display: inline-block;
					font-weight: 800;
					transition: $transition;
					color: $secondary;
					text-decoration: none;
					&.email {
						margin-right: 10px;
					}
					a {
						font-weight: 800;
						transition: $transition;
						color: $secondary;
						text-decoration: none;
						&:hover {
							color: $primary;
						}
					}
				}
			}
		}
	}

	.property-location {
		.map {
			height: 400px;
		}
	}

	.bottom-spacer {
		padding-top:50px;
		width:100%;
		display: block;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
		.property-additional-features {
			.feature {
				width: 200px;
				height: 200px;
				.feature-info {
					.title {
						font-size: 36px;
						font-weight: 500;
						margin-bottom: 10px;
					}
					.description {
						font-size: 16px;
					}
				}
			}
		}
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.property-detail-info {
			.intro {
				text-align: right;
			}
		}

		.property-visual-identity {
			.property-logo {
				text-align: left;
			}
			.property-gallery {
				margin-top: 30px;
				.paging-info {
					top: -33px;
				}
			}
			.slick-arrow {
				top:-30px;
			}
		}
		.property-leasing {
			text-align: left;
			.container {
				.leasing-intro {
					margin-bottom:0;
					text-align: right;
					&.no-agents {
						text-align: center;
					}
				}
			}
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
