.transition-element, .transition-element-exiting {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:$primary;
	z-index: 999;
	transition:transform 0.6s ease-in-out;
	&:after, &:before {
		content: "";
	    display: block;
	    width: 0;
	    height: 0;
	    position: absolute;
	}
}

.transition-element {
	&.hidden {
		transform:translateX(100%) translateX(30vh);
	}
	&:before {
		border-top: 100vh solid transparent;
	    border-right: 30vh solid $primary;
	    left: -30vh;
	}
}

.transition-element-exiting {
	transform:translateX(0);
	&.hidden {
		transform:translateX(-100%) translateX(-30vh);
	}
	&:after {
		border-bottom: 100vh solid transparent;
	    border-left: 30vh solid $primary;
	    right: -30vh;
	}

}


@keyframes grid-item-load {
    from {
        opacity: 0.1;
    }
    to {
        opacity: 1;
    }
}