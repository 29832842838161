.numeric-pagination {
	text-align: center;
	font-size: 15px;
	margin: 60px 0;
	a {
		color: $grey-dark;
		text-decoration: none;
	}
	i {
		vertical-align: -3px;
	}

	.page-numbers {
		width: 45px;
		position: relative;
		display: inline-block;
		line-height: 45px;
		transition: $transition;
		&.current {	
			background-color: $secondary;
			color:#fff;
			&:hover {
				background-color: $secondary;
			}
		}
		&:hover {
			background-color: $grey-light - 20;
		}
		&.next, &.prev {
			width: auto;
			transition: $transition;
			display:inline-block;
			.text {
				margin: 0 10px;
			}
			&:hover {
				background-color: transparent;
				color: $secondary;
			}
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}