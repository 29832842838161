.divider-container {
	.divider {
		padding:40px 0;
		width: 100%;
		display: block;
		text-align:center;
		position:relative;
		&:before {
			content:'';
			position:absolute;
			top:50%;
			transform:translateY(-50%);
			left:0;
			width:100%;
			height:1px;
			background-color:#d0d0d0;
		}
		i {
			vertical-align: -5px;
			position:relative;
			z-index:1;
			font-size: 40px;
			color:#d0d0d0;
			background-color: #fff;
			padding:13px;
			border-radius:50%;
		}
	}
}