.wrap .social {
	@include list();
	li {
		display: inline-block;
		margin-right: 10%;
		&:last-child {
			margin-right: 0;
		}
		a {
			font-size: 25px;
			color: $primary;
			text-decoration: none;
			transition: $transition;
			&:hover {
				color: $secondary;
			}
		}
	}
}