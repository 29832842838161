.notable-past-projects {
	.featherlight {
		.featherlight-content {
			border-bottom:0;
			overflow: hidden;
			.caption {
				color:$primary;
				font-family: $hFont;
				padding-top: 10px;
				font-size: 25px;
				padding-bottom:15px;
			}
		}
	}
}

.block.notable-projects {

	.block-title {
		margin-top: 0;
	    padding-bottom: 45px;
	    border-bottom: 1px solid #979797;
	    margin-bottom: 75px;
	}

	.projects-list {
		.project {
			margin-bottom:40px;
			.image-trigger {
				background: {
					size:center;
					position:center;
				}
				padding-bottom:56.25%;
			}
			.before-after {
				cursor:pointer;
				position: relative;
				display: block;
				img {
					display: block;
				}
				.magnifying-glass {
					position: absolute;
					bottom:0;
					right:0;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 82px 82px;
					border-color: transparent transparent $primary transparent;
					i {
					    position: absolute;
					    top: 48px;
					    right: 12px;
					    z-index: 1;
					    color: #fff;
					    font-size: 22px;
					}
				}
			}
		}
		.gallery-item {
			display: none;
			visibility: hidden
		}
		.title {
			margin:20px 0;
			padding-bottom:22px;
			position: relative;
			display: block;
			&:before {
				content:'';
				position: absolute;
				left:0;
				bottom:0;
				width:89px;
				height: 4px;
				background-color:$lightBrown;
			}
			.sub-title {
				color:#b3b3b3;
				display: block;
				font-size:80%;
			}
		}
		.location {
			color:#272727;
			font-weight: 600;
			margin-bottom: 5px;
			display: block;
		}
		.features {
			color:#272727;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


		.section-title {
			margin-top: 0;
			font-size:28px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}