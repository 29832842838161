.site-header {
	position: fixed;
	z-index: 100;
	width: 100%;
	top: 0;

	.admin-bar & {
		margin-top: 32px;
	}

	@media screen and (max-width: 783px) {
		.admin-bar & {
			margin-top: 46px;
		}
	}
	.site-info {
		transition: $transition;
		background-color: rgba(255, 255, 255, 0.6);
		position: relative;
		.shrink & {
			transform: translateY(0 - $utility-bar-height-desktop - 1);
			background-color: rgba(255, 255, 255, 1);
		}
		.row {
			height: $header-mobile-height;
			padding: 0 15px;
			position: relative;
		}

		.brand {
			img {
				max-width: 153px;
				display: block;
			}
		}

		.customer-service {
			@include media-breakpoint-down(md) {
				display: none;
			}
			a {
				text-decoration: none;
				color: $primary;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 12px;
				transition: color 0.3s ease;
				&:hover {
					color: $secondary;
					i {
						transform: scale(1.2);
					}
				}
				i {
					transition: transform 0.3s ease;
					display: inline-block;
					font-size: 15px;
					vertical-align: -2px;
					margin-right: 4px;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		.shrink & {
			height: $header-desktop-height;
		}
		.menu-trigger {
			display: none;
		}

		.site-info {
			.row {
				height: $header-desktop-height;
			}

			.brand {
				img {
					max-width: 183px;
				}
			}
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
