.block.social-media-slider {
	padding-top:50px;
	padding-bottom:100px;
	overflow: hidden;
	.wpsms-container {
		.owl-stage {
			@extend .row;
			margin-left:0;
			margin-right:0;
			.item {
				@extend .col;
			}
		}
		.wpsms .wpsms-item .wpsms-image+.wpsms-body-text {
			padding-left: 0;
		}
		.wpsms-magnific.wpsms-image {
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    top: 0px;
		    left: 0;
		    &:before {
		    	content:'';
				background-color: rgba(0,0,0,0.43);
				position: absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				z-index: 1;
		    }
		    img {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
		    }
		}
		.wpsms-item {
			padding:70px 40px;
			color:#fff;
			&.wpsms-twitter {
				background-color:#00b6f1;
				height: 100%;
				text-align:center;
				a {
					color:#fff;
				}
			}
			&.wpsms-facebook {
				background-color:#3b5998;
				height: 100%;
				text-align:center;
				a {
					color:#fff;
				}
			}
			&.wpsms-instagram {
				background-color:#4c68d7;
				height: 100%;
				text-align:center;
				a {
					color:#fff;
				}
			}
			.wpsms-meta-info {
				text-align: center;
				z-index: 2;
				position: relative;
				a, .post-time {
					display: none;
				}
				&.wpsms-icon-twitter {
					display: block;
				}
				&:before {
					float:none;
					display: inline-block;
				}
			}
			.wpsms-body-text {
				z-index: 1;
			}
		}

	}

	.owl-dots {
		@extend .container;
		padding-left:15px;
		padding-right: 15px;
		text-align: center;
		margin-top:30px;
		.owl-dot {
			margin-right: 10px;
			display: inline-block;
			span {
				display: block;
				cursor: pointer;
				width:68px;
				height: 3px;
				font-size: 0;
				appearance:none;
				border:none;
				background-color: #dadada;
				transition:$transition;
			}
			&.active {
				span {
					background-color: $secondary;
				}
			}
		}
	}
	.wpsms .owl-next, .wpsms .owl-prev {
		color:#000;
	}
	.owl-controls {
		.owl-nav {

		}
	}



	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {





	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.wpsms-container {
			.owl-carousel .owl-controls {
				position: relative;
			}
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}