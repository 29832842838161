.menus.desktop {
	display: none;
	ul.nav {
		list-style-type: none;
		padding: 0;
		text-align: right;
		margin: 0;
		& > li {
			display: inline-block;
			margin-right: 30px;
			position: relative;
			a {
				padding: 32px 0;
				text-decoration: none;
				color: $primary;
				text-transform: uppercase;
				transition: $transition;
				font-size: 14px;
				font-weight: 600;
				display: block;
			}
			& > a {
				&:before {
					content:'';
					position: absolute;
					width: 100%;
					height: 0px;
					background-color:$secondary;
					transition: $transition;
					bottom:0px;
				}
				&:hover {
					color: $secondary;
					&:before {
						height: 3px;
					}
				}
			}
			&.customer-service {
				display: none;
			}
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				ul {
					opacity: 1;
					visibility: visible;
					transition-delay: 0s, 0s;
					transform:translateY(0px);
				}
			}
			ul {
				opacity: 0;
				visibility: hidden;
				z-index: 1;
				transition: opacity 0.2s ease 0s,visibility 0s ease .2s,transform 0.2s ease 0s;
				transform:translateY(10px);
				background-color:rgba(255,255,255,1);
				@include list();
				position: absolute;
				left: 0;
				text-align: left;
				box-shadow:0px 4px 5px rgba(0,0,0,0.2);
				li {
					a {
						.shrink & {
							padding: 10px 25px;
						}
						font-weight: 500;
						padding: 10px 25px;
						color: $primary;
						white-space: nowrap;
						transition: $transition;
						&:hover {
							background-color: $primary;
							color: #fff;
						}
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


		


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		display: inline-block;
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}