.careers-list {
	.item {
		margin-bottom: 60px;
		padding-bottom: 60px;
		border-bottom: 1px solid #d8d8d8;
		&:last-child {
			border-bottom: 0;
		}
	}

	.location {
		font-size: 14px;
		color: #a0a0a0;
		span {
			color: $grey-dark;
		}
	}

	.title {
		text-transform: uppercase;
		font-weight: 500;
		margin-top: 10px;
	}

	.meta {
		margin-bottom: 20px;
		color: #272727;
		font-size: 16px;
		font-weight: 500;
		time {
			display: block;
		}
	}

	a {
		color: $secondary;
		font-weight: 500;
		transition: $transition;
		text-decoration: none;
		&:hover {
			color: $primary;
		}
		&.view-posting {
			font-weight: 800;
		}
	}

	.no-positions-available {
		text-align: center;
		line-height: 1.6em;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
