@media screen and (max-width: 600px) {
	#wpadminbar {
		margin-top: -46px;
	}
}

body {
	position: relative;
	&#tinymce {
		&:before {
			display: none;
		}
	}
	&:not(.wp-admin) {
		&:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			right: 0;
			background: {
				image: url("../images/background_triangle.svg");
				position: right;
				size: cover;
			}
		}
	}

	@include media-breakpoint-down(md) {
		.md-mb-2 {
			margin-bottom: 20px;
		}

		.md-mb-4 {
			margin-bottom: 40px;
		}
		.md-mb-8 {
			margin-bottom: 80px;
		}

		.mt-2 {
			margin-top: 20px;
		}

		.mt-4 {
			margin-top: 40px;
		}
	}

	&.menu-open {
		overflow: hidden;
		.wrap {
			&:before {
				opacity: 1;
				visibility: visible;
				transition-delay: 0s, 0s;
			}
		}
	}
	.wrap {
		&:before {
			z-index: 103;
			content: "";
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition-property: opacity, visibility;
			transition-duration: 0.2s, 0s;
			transition-delay: 0s, 0.2s;
			background-color: hsla(0, 0%, 100%, 0.6);
			opacity: 0;
			visibility: hidden;
		}
		position: relative;
		font-size: 16px;
		color: $pColor;
		font-family: $pFont;

		img {
			max-width: 100%;
			height: auto;
		}

		.columnize {
			break-inside: avoid;
			column-count:1;
			break-inside: avoid-column;
			.item {
				display: inline-block;
				width: 100%;
			}
		}

		/* =============================================================================
		Extra Small +
		========================================================================== */
		@include media-breakpoint-up(xs) {
		}

		/* =============================================================================
		Small +
		========================================================================== */
		@include media-breakpoint-up(sm) {
		}

		/* =============================================================================
		Medium +
		========================================================================== */
		@include media-breakpoint-up(md) {
			.columnize {
				column-count:2;
			}

		}

		/* =============================================================================
		Large +
		========================================================================== */
		@include media-breakpoint-up(lg) {
		}

		/* =============================================================================
		Extra Large +
		========================================================================== */
		@include media-breakpoint-up(xl) {
		}
	}
}
