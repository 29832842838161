.block.team-members {

	.member {
		margin-bottom:80px;	
		.placeholder {
			position:relative;
			width: 100%;
			padding-bottom:56.25%;
			background-color: #fff;
			i {
				font-size:22vw;
				color:#c9c9c9;
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
			}
		}
		img {
			display: block;
		}
		h3 {
			margin:15px 0 7px;
			position: relative;
			span {
				position: relative;
				z-index: 2;
			}
		}
		.title {
			font-size:14px;
			color:#7d7d7d;
			margin-bottom:15px;
			display: block;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.member {
			.placeholder {
				i {
					font-size:15vw;
				}
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.member {
			.placeholder {
				i {
					font-size:10vw;
				}
			}
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}