.contact-info {
	text-align: center;
	.our-offices {
	}
	.office-title {
		margin-bottom: 20px;
	}

	.address p {
		margin-bottom: 0;
		margin-top: 0;
	}
	.phone,
	.fax {
		display: block;
		a {
			color:$pColor;
			text-decoration:none;
		}
	}

	.social-links-container {
		margin-top: 45px;
		i {
			font-size:35px;
		}
	}

	.customer-service-line {
		padding-top:30px;
		.all-hours-number {
			color:$primary;
			text-decoration: none;
			transition:$transition;
			&:hover {
				color:$secondary;
			}
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		text-align: left;
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
