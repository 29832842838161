.footer-content {
	font-family: $pFont;
	font-size: 12px;

	.main-footer {
		padding-top: 60px;
		padding-bottom: 50px;
		background-color: #fff;
	
		.footer-col {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				height:1px;
				background-color: #a0a0a0;
				opacity: 0.4;
				bottom:-15px;
				left: 50%;
				width: calc(100% - 30px);
				transform:translate(-50%,-50%);
			}
			&:last-child {
				&:before {
					display: none;
				}
			}
			&.logo-menu, &.newsletter {
				margin-bottom:55px;
			}
		}

		h4 {
			margin-top: 0;
			text-transform: uppercase;
			color: $primary;
			font-weight: 800;
			font-size: 12px;
		}
		nav {
			ul {
				@include list();
				li {
					a {
						text-decoration: none;
						color: $grey-dark;
						transition: $transition;
						&:hover {
							color:$secondary;
						}
					}
				}
			}
		}
		
		.footer-logo {
			margin:20px 0 10px;
			display: none;
		}

		.mobile-footer-logo {
			margin:0px 0 60px;
			max-width: 299px;
		}

		.form-container {
			.gform_wrapper {
				margin:-15px 0 0 0;
				.top_label .gfield_label {
					display: none;
				}
				input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
					border:none;
					border-bottom:1px solid #979797;
					transition: $transition;
					height: 35px;
					&:focus {
						outline:none;
						border-bottom:1px solid $primary;
					}
				}
				.btn {
					font-family: $pFont;
					font-weight: 500;
					padding:13px 28px;
					text-transform: none;
				}
				.gform_footer {
					margin-top: 0;
				}
			}
		}
		.join-our-team-link {
			margin-top: 10px;
			display: block;
			color: $secondary;
			text-decoration: none;
			transition: color 0.3s ease;
			font-weight: 800;
			i {
				vertical-align: -4px;
				font-size: 15px;
				margin-left:6px;
				transition: margin-left 0.3s ease;
			}
			&:hover {
				color: $primary;
				i {
					margin-left: 9px;
				}
			}
		}

		.social {
			margin-top: 65px;
		}

	}

	.socket {
		padding: 12px 0;
		background-color: #e0e0e0;
		text-align: center;
		font-size: 11px;
		.nav {
			@include list();
			li {
				display: inline-block;
				margin-right: 5px;
				margin-bottom: 0;
				&:last-child {
					margin-right: 0;
					&:after {
						margin-left: 0px;
						content:'';
					}
				}
				&:after {
					transition: none;
					margin-left: 10px;
					content:'|';
				}
				a {
					text-decoration: none;
					color: $grey-dark;
					transition:$transition;
					&:hover {
						color:$secondary;
					}
				}
			}
		}
		.attribution {
			display: block;
			color: $grey-dark;
			text-decoration: none;
			span, i {
				vertical-align: middle;
				display: inline-block;
			}
			i {
			    vertical-align: -4px;
			    font-size: 15px;
			    margin-left: 1px;
			}
		}

	}
	
	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.main-footer {
			
			.footer-col {
				&:before {
					left: auto;
					width:1px;
					right:25px;
					top:0;
					height: 100%;
					transform:none;
				}
				&.newsletter {
					.content, .form-container {
						max-width: 90%;
					}
				}
				&.logo-menu, &.newsletter {
					margin-bottom:0px;
				}
			}

			.footer-logo {
				margin:65px 0 0;
				display: block;
				max-width: 250px;
			}
			.mobile-footer-logo {
				display: none;
			}
		}
		.socket {
			text-align: initial;
			.nav {
				text-align: center;
			}
			.attribution {
				text-align: right;
			}
		}


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}