.block.newsletter {
	padding-top:40px;
	text-align: center;
		.container {
			.row-border {
				margin-right: 0;
				margin-left: 0;
				border: 3px solid $lightBrown;
				padding-top:50px;
				padding-bottom:100px;
			}
			@include media-breakpoint-down(md) {
				.hide {
					display: none;
				}
			} 
			.gform_wrapper {
				.gform_footer {
					text-align: center;
				}
			}
		}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		text-align: left;
		.container {
			.gform_wrapper {
				.gform_footer {
					text-align: left;
				}
			}
		}
		.content {
			text-align:right;
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}