.btn {
	display: inline-block;
	text-decoration: none;
	transition: $transition;
	text-align: center;
	position: relative;
	padding: 18px 39px;
	outline:none;
	border:none;
	appearance:none;
	background-color: $secondary;
	color:#fff;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
	&:hover {
		background-color: $primary;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}

}


