.interior-intro {
	position:relative;
	padding:0;
	min-height: 40vw;
	margin-top:0;
	&:before {
		content:'';
		position:absolute;
		width:100%;
		left:0;
		top:0;
		height: 100%;
		background: {
			position:center;
			size:cover;
		}
		@include media-breakpoint-down(sm) {
			background-image:none!important;
		}
	}
	.row {
		min-height: 40vw;
		.inner {
			z-index: 1;
			position:relative;
			padding:10px 0 50px;
			text-align: center;
			h1 {
				font-family: $h1Font;
			}
			.gform_wrapper {
				margin-top:60px;
				.gform_footer {
					text-align: center;
				}
			}
		}
	}

	.mobile-image {
		padding-bottom:55vh;
		background: {
			size:cover;
			position:center;
			repeat:no-repeat;
		}
		margin-bottom:40px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	
		margin-top: calc(#{$utility-bar-height-desktop} + #{$header-desktop-height});

		&:before {
			width:41.67%;
		}
		.row {
			margin-top: 0;
			min-height:calc(100vh - ( #{$utility-bar-height-desktop} + #{$header-desktop-height}) );
			.inner {
				color:$pColor;
				max-width:90%;
				margin:0 auto;
				text-align: left;
				.gform_wrapper {
					margin-top:0px;
					.gform_footer {
						text-align: left;
					}
				}
			}
		}
		.mobile-image {
			display: none;
			margin-bottom:0px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}