.utility-bar {
	background-color: #fff;
	border-bottom:1px solid #dadada;
	overflow: hidden;
	ul {
		@include list();
		height: $utility-bar-height-desktop;
		li {
			display: inline-block;
			a, span {
				padding:10px 20px;
				display: block;
				height: $utility-bar-height-desktop;
				width:100px;
				position: relative;
				&:after {
					content:'';
					top:0;
					right:0;
					height: 100%;
					position: absolute;
					width:1px;
					background-color: #dadada;
				}
				img, svg {
					position: absolute;
					top: 50%;
					left: 50%;
					transform:translate(-50%,-50%);
					max-width: 62px;
				}
			}
			&.active {
				svg {
				  	path {
				  		transition:$transition;
					}
					&:not(:hover){
						path {
							fill: #808184!important;
					  	}
					}
				}
			}
		}


	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}