.single-career {
	&:before {
		display: none;
	}
	article {
		border-top:1px solid #d8d8d8;
		padding-top: 30px;
		margin-top:calc(5vw + #{$header-mobile-height});
	
		.back-button {
			.container {
				padding-bottom:30px;
				border-bottom:1px solid #d8d8d8;
			}
			a {
				font-size: 12px;
				color:#484848;
				text-decoration: none;
				transition:$transition;
				&:hover {
					color:$secondary;
				}
			}
		}

		.career-info {
			margin-top: 40px;
			.career-title {
				margin-top:0;
				font-family: $h1Font;
			}
			.meta {
				margin-bottom:50px;
				span, time {
					display: block;
				}
			}
			strong {
				color:#272727;
			}
		}

		.application-form {
			margin-top: 50px;
			max-width:460px;
			.title {
				color:#484848;
				font-weight: 700;
			}
		}

		/* =============================================================================
		Extra Small +
		========================================================================== */ 
		@include media-breakpoint-up(xs) {

		} 

		/* =============================================================================
		Small +
		========================================================================== */ 
		@include media-breakpoint-up(sm) {

		}


		/* =============================================================================
		Medium +
		========================================================================== */
		@include media-breakpoint-up(md) {

			
			margin-top:calc(5vw + #{$header-desktop-height});
			

		} 

		/* =============================================================================
		Large +
		========================================================================== */
		@include media-breakpoint-up(lg) {
		
		} 

		/* =============================================================================
		Extra Large +
		========================================================================== */
		@include media-breakpoint-up(xl) {
		
		}
	}
}