.portfolio-list {
	min-height: 150px;
	opacity: 0.1;
	&.animate {
		animation: grid-item-load 0.3s normal forwards ease-in-out;
	}

	.no-results {
		display: none;
		width: 100%;
		text-align: center;
		padding-top: 55px;
		font-size: 24px;
		color: #bababa;
	}

	.grid-item {
		text-align: left;
		margin-bottom: 60px;
		a {
			text-decoration: none;
			color: $primary;
		}
		.thumb {
			position: relative;
			img {
				width: 100%;
				display: block;
				margin: 0 auto;
			}
		}
		.details {
			padding-left: 20px;
			position: relative;
			.title-city {
				position: relative;
				z-index: 1;
				max-width: calc(100% - 189px);
				h4 {
					margin: 0;
					padding-top: 20px;
					font-size: 24px;
					margin-bottom: 10px;
					line-height: 1.25em;
					span {
						margin-top: -7px;
						color:#b3b3b3;
						display: block;
						font-size: 16px;
						
					}
				}

				.city {
					font-size: 14px;
					color: $primary;
					font-weight: 500;
					position: relative;
					padding-bottom: 15px;
					&:after {
						content: "";
						position: absolute;
						bottom: 0px;
						left: 0;
						width: 100%;
						height: 2px;
						background-color: #d0c492;
					}
				}
			}
			.explore {
				position: absolute;
				right: 0px;
				height: calc(100% + 15px);
				max-height: 130px;
				width: 113px;
				top: 0;
				background-color: #f2f2f2;
				transition: $transition;
				overflow: hidden;
				.mask {
					transition: $transition;
					position: absolute;
					top: 0;
					width: 0;
					height: 0;
					padding-bottom: 130%;
					padding-left: 100%;
					overflow: hidden;
					&:after {
						content: "";
						display: block;
						width: 0;
						height: 0;
						margin-left: -500px;
						border-bottom: 1000px solid transparent;
						border-left: 450px solid #fff;
					}
				}
				i {
					transition: color 0.3s ease;
					font-size: 20px;
					color: $secondary;
					right: 23px;
					position: absolute;
					top: 50%;
					transform: translateY(-46%);
				}
				.text {
					font-weight: 600;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 55px;
					color: #fff;
					opacity: 0;
					transition: $transition;
				}
				&:hover {
					width: 189px;
					background-color: $secondary;
					i {
						color: #fff;
					}
					.mask {
						padding-left: 58%;
					}
					.text {
						opacity: 1;
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.grid-item {
			padding-bottom: 80px;
			margin-bottom: 0px;
			.details {
				.title-city {
					h4 {
						font-size: 30px;
					}
					.city {
						font-size: 16px;
					}
				}
			}
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}

	@media screen and (min-width: 1400px) {
		.grid-item {
			text-align: center;
			.details {
				.title-city {
					max-width: calc(100% - 378px);
					margin: 0 auto;
				}
			}
		}
	}
}
