.wrap {

	font-size: 16px;
	line-height: 26px;
	font-weight: 500;

	h1,h2,h3,h4,h5,h6 {
		color:$hColor;
		line-height: 1em;
		font-weight: 500;
		a {
			color:$secondary;
			transition:all 0.3s ease;
			text-decoration: none;
			&:hover {
				color:$primary;
			}
		}
	}

	h1 {
		&.adelle {
			font-family: $h1Font;
			font-weight: 100;
		}
	}

	p {
		&:first-child {
			margin-top: 0;
		}
	}
	

	p, li {
		a {
			color:$secondary;
			transition:$transition;
			&:hover {
				color:$primary;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	

		h1 {
			font-size: 55px;
		}
		h2 {
			font-size: 42px;
		}
		h3 {
			font-size:36px;
		}
		h4 {
			font-size:24px;
			line-height: 1.4em;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}