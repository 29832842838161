.menus.mobile {
	position: fixed;
	height: 100%;
	width: 66.66%;
	z-index: 150;
	display: block;
	background-color: $primary;
	top: 0;
	transform:translateX(100%);
	transition: $transition;
	overflow-y: auto;
	padding: 30px 20px 80px;
	min-width:280px;
	right: 0;
	.menu-open & {
		transform: translateX(0);
	}

	.admin-bar & {
		top: calc(32px);
	}

	.menu-trigger {
		top:30px;
		.bar {
			background-color: #fff;
			width: 100%;
			height: 2px;
			&.bar-1 {
				top: 6px;
				transform:rotate(45deg);
			}
			&.bar-2 {
				top: 6px;
				transform:rotate(-45deg);
			}
		}
	}

	nav {
		ul {
			@include list();
		}
		&.nav-primary {
			ul {
				li {
					margin-bottom: 0px;
					a {
						font-family: $h1Font;
						font-size: 24px;
						color:$lightBrown;
						display: block;
						text-decoration:none;
						padding:12px 10px;
						transition: $transition;
						&:hover {
							color:#fff;
							background-color:$primary;
						}
					}
					.sub-menu {
						padding-bottom:20px;
						li {
							padding-left:30px;
							margin-bottom:0px;
							a {
								font-weight: normal;
								font-size: 18px;
								font-family: $pFont;
								padding:6px 10px;
							}
						}
					}
					&.customer-service {
						background-color: #002129;
						display: block;
						text-transform: uppercase;
					    width: calc(100% + 70px);
					    margin-left: -35px;
					    padding:11px 0;
					    margin-top:25px;
					    &:hover {
					    	background-color: #002129;
					    }
					    a {
					    	font-size:16px;
							&:hover {
						    	background-color: #002129;
						    }
					    	font-family: $pFont;
					    	color:#fff;
						    margin-left: 35px;
						    i {
						    	font-size: 24px;
						    	margin-right: 10px;
						    	vertical-align: -4px;
						    }
						}
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
	
}
