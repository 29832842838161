.property-list {
	min-height: 150px;
	.no-results {
		display: none;
		width: 100%;
		text-align: center;
		padding-top: 55px;
		font-size: 24px;
		color: #bababa;
	}
	.grid-item {
		margin-bottom: 20px;

		a {
			text-decoration: none;
			color: $primary;
		}
		.thumb {
			img {
				width:100%;
				display: block;
			}
		}
		.details {
			padding: 10px 0;
			min-height: 100px;
			position: relative;
			.property-title {
				z-index: 2;
				max-width: 50%;
				h4 {
					font-size: 24px;
					margin: 0;
				}
				h5 {
					font-size: 16px;
					margin: 5px 0 0;
				}
			}

			.location {
				position: relative;
				z-index: 2;
				.city {
					margin-top: 5px;
					font-size: 16px;
					display: block;
					color: #272727;
				}
			}
			.explore {
				position: absolute;
				right: 15px;
				height: 100%;
				width: 103px;
				top: 0;
				background-color: #f2f2f2;
				transition: $transition;
				overflow: hidden;
				max-height: 130px;
				.mask {
					transition: $transition;
					position: absolute;
					top: 0;
					width: 0;
					height: 0;
					padding-bottom: 130%;
					padding-left: 100%;
					overflow: hidden;
					&:after {
						content: "";
						display: block;
						width: 0;
						height: 0;
						margin-left: -500px;
						border-bottom: 1000px solid transparent;
						border-left: 450px solid #fff;
					}
				}
				i {
					transition: color 0.3s ease;
					font-size: 20px;
					color: $secondary;
					right: 23px;
					position: absolute;
					top: 50%;
					transform: translateY(-46%);
				}
				.text {
					font-weight: 600;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 55px;
					color: #fff;
					opacity: 0;
					transition: $transition;
				}
			}
		}
		&:hover {
			.details {
				.explore {
					width: 189px;
					background-color: $secondary;
					i {
						color: #fff;
					}
				}
				.mask {
					padding-left: 58%;
				}
				.text {
					opacity: 1;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		/**
		 *	List display styles
		 */

		.list {
			.grid-item {
				margin-bottom: 30px;
				.thumb {
					float: left;
				}
				h4 {
					font-size: 30px;
				}
				.city {
					margin-top: 0;
					font-size: 24px;
				}
				.details {
					height: 100%;
					.explore {
						height: 100px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		.grid-item {
			.details {
				.property-title {
					&:before {
						content: "";
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						position: absolute;
						width: 2px;
						height: 66px;
						background-color: #dadada;
					}
				}
			}
		}
	}
}
