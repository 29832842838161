.slider {
	position: relative;
	padding-top:0;
	padding-bottom:70px;
	overflow-y:hidden;
	max-height: calc(100vh);
	overflow: hidden;
	&:after {
		content:'';
		position:absolute;
		bottom:0;
		left:50%;
		transform:translateX(-50%);
		height: 1px;
		background-color:$primary;
		width:calc(100% - 30px);
		max-width: 540px;
	}
	.item {
		padding-top:calc(#{$utility-bar-height-desktop});
		background: {
			size:cover;
			position:center;
			repeat:no-repeat;
		}
		@include media-breakpoint-down(sm) {
			background-image:none!important;
		}

		h1 {
			margin-top: 0;
			color: $primary;
			margin-bottom:10px;
		}
		.image {
			width: 100vw;
			height: 56.25vw;
			max-height: 100vh;
			background: {
				size:cover;
				position:center;
				repeat:no-repeat;
			}

			margin-bottom:50px;
		}
		.description {
			color: $primary;
			font-size: 16px;
			line-height: 1.62;
			font-weight: normal;
		}

		.btn {
			margin-top: 30px;
		}
	}

	.slick-dots {
		@include list();
		@extend .container;
		position: absolute;
		top:calc(#{$header-mobile-height} + #{$utility-bar-height-desktop});
		left:50%;
		transform:translateX(-50%);
		padding-left:15px;
		padding-right: 15px;
		margin-top:calc(56.25vw - #{$header-mobile-height} - #{$utility-bar-height-desktop});
		li {
			margin-right: 10px;
			display: inline-block;
			button {
				cursor: pointer;
				width:68px;
				height: 3px;
				font-size: 0;
				appearance:none;
				border:none;
				background-color: #fff;
				transition:$transition;
				&:focus {
					outline:none;
				}
			}
			&.slick-active {
				button {
					background-color: $secondary;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		
		padding-bottom:0;
		&:after {
			display: none;
		}
		.item {
			position: relative;
			.container {
				position: absolute;
				top:calc(2vw + #{$header-desktop-height} + #{$utility-bar-height-desktop});
				left:50%;
				transform:translateX(-50%);
			}
			.image {
				margin-bottom: 0;
			}
			h1 {
				color:#fff;
				font-size: 35px;
			}
			.description {
				color:#fff;
			}
		}
		.slick-dots {
			top:auto;
			bottom:30px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.item {
			padding-top:calc(#{$utility-bar-height-desktop});
			.container {
				top:calc(9vw + #{$header-desktop-height} + #{$utility-bar-height-desktop});
			}
			h1 {
				font-size: 55px;
			}
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}