.property-sorting {
	text-align: center;
	position: relative;
	&:before, &:after {
		content:'';
		position:absolute;
		top:0;
		width: 5%;
		height: 100%;
		z-index: 2;
	}
	&:before {
		left:0;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,c9c9c9+100&1+0,0+100 */
		background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
	}
	&:after {
		right:0;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
		background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
	}

	@include media-breakpoint-down(sm) {
		max-width: calc(100% - 30px);
		margin:0 auto;
		.row {
			position:relative;

		}
		[class^='col-'] {
			overflow-y:hidden;
			overflow-x:auto;

			/* hide scroll bar stuff */
		    -ms-overflow-style: none;  // IE 10+
		    overflow: -moz-scrollbars-none;  // Firefox
		    &::-webkit-scrollbar { 
			    display: none;  // Safari and Chrome
			}

			.property-status-selector, .property-type-selector {
				display: flex;
				margin:0 auto;
				padding:20px 0;
				li {
					h3 {
						margin:0;
					}
					a {
						white-space:nowrap;
					}
					&:last-child {
						padding-right:40px;
					}
				}
			}
		}
	}

	.page-template-template-leasing & {
		padding:80px 0 70px;
	}

	.page-template-template-portfolio & {
		padding:80px 0 50px;	
	}

	.property-type-selector, .display-type-selector {
		@include list();
	}

	.property-type-selector {
		li {
			display: inline-block;
			margin-right: 40px;
			&:last-child {
				margin-right: 0;
			}
		}
		h3 {
			@include media-breakpoint-down(md) {
				font-size: 30px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 26px;
			}
			margin:0 0 0px;
			a {
				position: relative;
				text-decoration: none;
				color:$primary;
				&:before {
					transition:width 0.3s ease;
					content:'';
					position: absolute;
					width: 0;
					max-width: 100%;
					height: 3px;
					bottom:-10px;
					left:0;
					background-color: $secondary;
				}
				&.active, &:hover {
					&:before {
						width:78px;
					}
				}
				&.active {
					font-weight: 600;
				}
			}
		}
	}

	.display-type-selector {
		display: none;
		li {
			display: inline-block;
			&.grid {
				font-size: 35px;
				margin-right: 20px;
			}
			&.list {
				font-size: 45px;
				i {
					vertical-align: -5px;
				}
			}
			a {
				text-decoration: none;
				color:#a0a0a0;
				transition:$transition;
				&.active {
					color:$primary;
					cursor: default;
				}
				&:hover {
					color:$primary;
				}
			}
		}
	}

	.property-status-selector {
		@include list();
		margin-top:40px;
		display: block;
		li {
			display: inline-block;
			position:relative;
			padding-right:30px;
			margin-right:25px;
			&:after {
				content:'';
				position:absolute;
				right:0;
				top:50%;
				transform:translateY(-50%);
				height: 65%;
				width:2px;
				background-color:#272727;
			}
			&:last-child {
				margin-right: 0;
				padding-right: 0;
				&:after {
					display: none;
				}
			}
			h4 {
				margin:0;
			}
			a {
				color:#272727;
				text-decoration: none;
				transition:$transition;
				&:hover, &.active {
					color:$secondary;
				}
			}
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		text-align: left;

		.display-type-selector {
			text-align: right;
			display: block;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}