.interior-banner {
	padding-top:0;
	padding-bottom:0;
	.background {
		padding-top:calc(#{$header-mobile-height} + #{$utility-bar-height-desktop});
		padding-bottom:5vw;
		background: {
			size:cover;
			position:center;
			repeat:no-repeat;
		}
		@include media-breakpoint-down(sm) {
			background-image:none!important;
		}

		h1 {
			margin-bottom:0;
			margin-top: 0;
			color: $primary;
		}

		.description {
			margin-top:30px;
			color: $primary;
			font-size: 16px;
			line-height: 1.62;
			font-weight: normal;
		}

		.mobile-image {
			padding-bottom:45vw;
			background: {
				size:cover;
				position:center;
				repeat:no-repeat;
			}
			margin-bottom:40px;
		}

	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.background {
			padding-top:calc(5vw + #{$header-desktop-height});
			h1 {
				color: #fff;
			}
			.description {
				color: #fff;
			}
			.mobile-image {
				display: none;
				margin-bottom:0px;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}