.contact-callout {
	background-repeat: no-repeat;
	background-position: 41%;
	background-size: cover;
	padding-bottom: 100vw;
	text-align: center;

	.title {
		margin-bottom: 10px;
	}
	.subtitle {
		margin-top: 20px;
		margin-bottom: 5px;
	}
	.btn {
		margin-top: 5px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		text-align: left;
		padding-bottom: 32vw;
		background-position: top center;
		.subtitle {
			font-size: 24px;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
