.menu-trigger {
	display: block;
	position: absolute;
	right: 20px;
	top: 15px;
	width: 28px;
	height: 45px;
	color: #fff;
	font-size: 0.625em;
	text-transform: uppercase;
	text-decoration: none;
	z-index: 4;
	transition: 0.3s;
	&:hover {
		.bar-1, .bar-2, .bar-3 {
			width:100%!important;
		}
	}
	.bar {
		position: absolute;
		left: 0px;
		background-color: $primary;
		height: 2px;
		display: block;
		transition: right 0.3s ease, transform 0.3s ease;
		transform: rotate(0deg);
		transform-origin: center center;
		border-radius: 5px;
		transition:$transition;
		&.bar-1 {
			right: 0px;
			top: 12px;
			width:60%;
		}
		&.bar-2 {
			right: 0px;
			top: 20px;
			width:80%;
		}
		&.bar-3 {
			right: 0px;
			top: 28px;
		}
	}

}